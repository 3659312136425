















import { Component, Prop, Vue } from 'vue-property-decorator';


@Component({
    name: 'modules__budget__staffing-table__common__Overlay',
})
export default class Overlay extends Vue {
    // region Model, properties
    @Prop({
        type: Boolean,
        default: false,
        required: false,
    })
    public readonly show!: boolean;
    // endregion
}
