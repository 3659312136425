













import { Component, Prop, Vue } from 'vue-property-decorator';


const clickEvent = 'click';


@Component
export default class FlatButton extends Vue {
    @Prop({
        type: String,
        required: false,
        default: null,
    })
    public text!: string | null;

    @Prop({
        type: Boolean,
        required: false,
        default: false,
    })
    public disabled!: boolean;

    private onClick(ev: MouseEvent) {
        if (this.disabled) return;
        this.$emit(clickEvent, ev);
    }
}
