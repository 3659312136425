








































































import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import I18n from '../I18n';
import { Comp } from '../types';
import FlatButton from './FlatButton.vue';
import SvgIcon from './SvgIcon.vue';
import svgIcons from './svg-icons';


const modelChangeEvent = 'change';
const clickEvent = 'click';
const collapseEvent = 'collapse';
const expandEvent = 'expand';
const iconPath = svgIcons.mdi['chevron-left'];
const i18n = new I18n('modules.budget.staffing_table.components.ListItem');


@Component({
    components: {
        FlatButton,
        SvgIcon,
    },
})
export default class ListItem extends Vue {
    // region Model, properties
    @Model(
        modelChangeEvent,
        {
            type: Boolean,
            required: false,
            default: null,
        },
    )
    public value!: boolean | null;

    @Prop({
        type: Boolean,
        required: false,
        default: false,
    })
    public clickable!: boolean;

    @Prop({
        type: Boolean,
        required: false,
        default: false,
    })
    public active!: boolean;

    @Prop({
        type: String,
        required: false,
        default: null,
    })
    public variant!: Comp.ColorVariant | null;
    // endregion


    // region Lifecycle
    // noinspection JSUnusedLocalSymbols
    private created() {
        this.$watch('value', (value: boolean | null) => {
            if ((value !== null) && (this.expanded !== value)) {
                this.expanded = value;
            }
        });
        this.$watch('expanded', (expanded: boolean) => {
            if (expanded !== this.value) {
                this.$emit(modelChangeEvent, expanded);
            }
        });
    }

    // noinspection JSUnusedLocalSymbols
    private mounted() {
        if (typeof this.value === 'boolean') {
            this.expanded = this.value;
        }
        this.checkCollapsibleSlot();
    }

    // noinspection JSUnusedLocalSymbols
    private updated() { this.checkCollapsibleSlot(); }
    // endregion


    // region Private data, methods
    private i18n = i18n;
    private iconPath = iconPath;
    private expanded = false;
    private hasCollapsibleSlot = false;

    private onMainClick(ev: MouseEvent) { this.$emit(clickEvent, ev); }

    private onCollapseButtonClick(ev: MouseEvent) {
        if (!this.hasCollapsibleSlot) return;

        if (this.expanded) {
            this.expanded = false;
            this.$emit(collapseEvent, ev);
        } else {
            this.expanded = true;
            this.$emit(expandEvent, ev);
        }
    }

    private checkCollapsibleSlot() {
        this.hasCollapsibleSlot = (this.$slots['collapsible'] !== undefined);
    }
    // endregion


    // region For expand/collapse animations
    private onBeforeEnter(el: HTMLElement) {
        el.style.height = '0';
    }

    private onEnter(el: HTMLElement) {
        setTimeout(() => {
            el.style.height = `${el.scrollHeight}px`;
        });
    }

    private onAfterEnter(el: HTMLElement) {
        el.style.height = '';
    }

    private onBeforeLeave(el: HTMLElement) {
        el.style.height = `${el.offsetHeight}px`;
    }

    private onLeave(el: HTMLElement) {
        setTimeout(() => {
            el.style.height = '0';
        });
    }

    private onAfterLeave(el: HTMLElement) {
        el.style.height = '';
    }
    // endregion
}
