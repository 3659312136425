














































import { Component, Model, Vue } from 'vue-property-decorator';
import auth from '@/services/auth';
import { Ax } from '@/utils';
import { store } from '../common';
import { ListItem, ListItemPart, Overlay, Pagination } from '../components';
import I18n from '../I18n';
import { Comp, Dict, Org, Utils } from '../types';


const modelChangeEvent = 'change';
const i18n = new I18n('modules.budget.staffing_table.components.SingleGuSelection');
const noValue = Symbol('modules/budget/staffing-table/components/SingleGuSelection.vue :: No value');


@Component({
    components: {
        'list-item': ListItem,
        'list-item-part': ListItemPart,
        'overlay': Overlay,
        'pagination': Pagination,
    },
})
export default class SingleGuSelection extends Vue {
    // region Model, properties
    @Model(
        modelChangeEvent,
        {
            type: Object,
            required: false,
            default: noValue,
        },
    )
    public value!: Org.Gu | null | symbol;
    // endregion


    // region Lifecycle
    // noinspection JSUnusedLocalSymbols
    private created() {
        // region Model, properties
        this.$watch('value', (value: Org.Gu | null | symbol) => {
            if (typeof value === 'symbol') return;
            if (this.localValue?.code !== value?.code) {
                this.localValue = value;
            }
        });
        // endregion


        // region ABP list
        this.$watch('selectedAbp', (selectedAbp: Dict.EbkFunc | null) => {
            if (selectedAbp !== null) {
                store.abp = selectedAbp;
                this.reloadGuList();
            }
        });
        // endregion


        // region GU list
        this.$watch('itemsPerPage', () => {
            if (this.page === 0) {
                this.reloadGuList();
            } else {
                this.page = 0;
            }
        });
        this.$watch('page', () => {
            this.reloadGuList();
        });
        this.$watch('localValue', (localValue: Org.Gu | null) => {
            if ((typeof this.value === 'symbol') || (this.value?.code !== localValue?.code)) {
                this.$emit(modelChangeEvent, localValue);
            }
        });
        // endregion
    }

    // noinspection JSUnusedLocalSymbols
    private mounted() {
        if (typeof this.value !== 'symbol') {
            this.localValue = this.value;
        }
        this.reloadAbpList();
    }
    // endregion


    // region Utils
    private i18n = i18n;

    private toast(type: 'danger' | 'warning' | 'success', title: string, message: string) {
        this.$bvToast.toast(message, {
            title: title,
            variant: type,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }
    // endregion


    // region Misc data
    private get userId(): string {
        return auth.user.sub;
    }

    private get loading(): boolean {
        return (this.abpLoading || this.guLoading);
    }

    private get isKazakh(): boolean {
        return this.i18n.isKazakh;
    }
    // endregion


    // region ABP list
    private abpLoading = false;

    private abpList: Array<Dict.EbkFunc> = [];

    private get abpOptions(): Array<Comp.DropdownItemDef<Dict.EbkFunc>> {
        const isKazakh = this.isKazakh;

        return this.abpList.map(abp => {
            let text: string;
            if (isKazakh) {
                text = abp.nameKk || abp.nameRu || (abp.id + '');
            } else {
                text = abp.nameRu || abp.nameKk || (abp.id + '');
            }

            let code = (abp.abp?.toString() || '');
            while (code.length < 3) {
                code = '0' + code;
            }

            return {
                value: abp,
                text: `${code} - ${text}`,
            };
        });
    }

    private selectedAbp: Dict.EbkFunc | null = null;

    private reloadAbpList() {
        if (this.abpLoading) {
            console.error('Cannot load ABP list - another loading is running');
            return;
        }

        const userId = this.userId;
        this.abpLoading = true;

        Ax<Array<Dict.EbkFunc>>(
            {
                url: `/api/budget/staffing_table/abp/list-for-user/${userId}`,
            },
            data => {
                this.abpList = data;

                const savedAbp = store.abp;
                if (savedAbp === null) {
                    this.selectedAbp = null;
                } else {
                    const selectedAbp = data.find((loadedAbp) => {
                        return (
                            (loadedAbp.gr === savedAbp.gr)
                            &&
                            (loadedAbp.pgr === savedAbp.pgr)
                            &&
                            (loadedAbp.abp === savedAbp.abp)
                        );
                    });

                    this.selectedAbp = (selectedAbp ?? null);
                }
            },
            error => {
                this.toast('danger', this.i18n.translate('cannot_load_abp'), error.toString());
            },
            () => {
                this.abpLoading = false;
            },
        );
    }
    // endregion


    // region GU list
    private page = 0;
    private totalGuCount = 0;

    private itemsPerPage = 25;

    private guLoading = false;

    // noinspection JSMismatchedCollectionQueryUpdate
    private guList: Array<Org.Gu> = [];

    private localValue: Org.Gu | null = null;

    private reloadGuList() {
        if (this.guLoading) {
            console.error('Cannot load GU list - another loading is running');
            return;
        }

        const selectedAbp = this.selectedAbp;
        if (selectedAbp === null) {
            console.error('Cannot load GU list - no selected ABP');
            return;
        }

        this.guLoading = true;

        Ax<Utils.PaginationList<Org.Gu>>(
            {
                url: `/api/budget/staffing_table/gu/list?items-per-page=${this.itemsPerPage}&page=${this.page}&user-id=${this.userId}&abp-code=${selectedAbp.abp}`,
            },
            data => {
                if (this.page !== data.page) {
                    setTimeout(() => {
                        this.page = data.page;
                    });
                    return;
                }


                this.totalGuCount = data.itemCount;
                this.guList = data.items;

                const savedGu = this.value;
                if ((typeof savedGu === 'symbol') || (savedGu === null)) {
                    this.localValue = null;
                } else {
                    const localValue = data.items.find((loadedGu) => {
                        return (loadedGu.code === savedGu.code);
                    });

                    this.localValue = (localValue ?? null);
                }
            },
            error => {
                this.toast('danger', this.i18n.translate('cannot_load_gu'), error.toString());
            },
            () => {
                this.guLoading = false;
            },
        );
    }

    private itemsPerPageChange(value: number) { this.itemsPerPage = value; }

    private onGuClick(gu: Org.Gu) {
        if (gu.code !== this.localValue?.code) {
            this.localValue = gu;
        }
    }

    private getGuId(gu: Org.Gu): string { return (gu.gu.id ?? '').toString(); }
    private getGuCode(gu: Org.Gu): string { return (gu.gu.code); }

    private getGuName(gu: Org.Gu): string {
        if (this.i18n.isKazakh) {
            return gu.nameKk;
        } else {
            return gu.nameRu;
        }
    }

    private getGuItemVariant(gu: Org.Gu): Comp.ColorVariant | null {
        if (this.localValue?.code === gu.code) {
            return 'primary';
        } else {
            return null;
        }
    }
    // endregion
}
