


























import { Component, Prop, Vue } from 'vue-property-decorator';
import I18n from '../I18n';


const lineSeparator = '\r\n';
const i18n = new I18n('modules.budget.staffing_table.components.ListItemPart');
const maxWidth = 'calc(100% - 4px)';


@Component({
    computed: {
        i18n() {
            return i18n
        }
    }
})
export default class ListItemPart extends Vue {
    // region Title
    @Prop({
        type: String,
        required: false,
        default: null,
    })
    public title!: string | null;

    private get preparedTitle(): string | null { return (this.title?.trim() ?? null); }

    public get hasTitle(): boolean { return (this.preparedTitle !== null); }

    @Prop({
        type: String,
        required: false,
        default: null,
    })
    public titleTooltip!: string | null;

    private get actualTitleTooltip(): string | null {
        const titleTooltip = this.titleTooltip;
        if (titleTooltip !== null) return titleTooltip;

        const title = this.preparedTitle;
        if (title !== null) return title;

        return null;
    }

    @Prop({
        type: String,
        required: false,
        default: null,
    })
    public titleForClipboard!: string | null;
    // endregion


    // region Text
    @Prop({
        type: String,
        required: true,
    })
    public text!: string;

    @Prop({
        type: String,
        required: false,
        default: null,
    })
    public textTooltip!: string | null;

    @Prop({
        type: String,
        required: false,
        default: null
    })
    public textForClipboard!: string | null;

    private get actualTextTooltip(): string {
        const textTooltip = this.textTooltip;
        if (textTooltip !== null) return textTooltip;

        return this.text;
    }
    // endregion


    // region Width
    @Prop({
        type: String,
        required: false,
        default: null,
    })
    public width!: string | null;

    private maxWidth = maxWidth;

    private get actualWidth(): string { return this.width ?? this.maxWidth; }
    // endregion


    // region Other
    private get clipboardHint(): string { return i18n.translate('clipboard_hint'); }

    private appendClipboardHint(text: unknown): string | null {
        if (typeof text !== 'string') return null;
        return text + lineSeparator + '[' + this.clipboardHint + ']';
    }

    private onClick(ev: MouseEvent, item: 'title' | 'text') {
        if (ev.detail !== 2) return;

        let text: string | undefined;
        switch (item) {
            case 'text':
                if (this.textForClipboard !== null) {
                    text = this.textForClipboard;
                } else {
                    text = this.actualTextTooltip;
                }
                break;
            case 'title':
                if (this.titleForClipboard !== null) {
                    text = this.titleForClipboard;
                } else {
                    text = this.actualTitleTooltip ?? undefined;
                }
                break;
        }

        if (text === undefined) return;

        navigator.clipboard.writeText(text);

        this.$bvToast.toast(
            i18n.translate('copied_to_clipboard'),
            {
                autoHideDelay: 1000,
                toaster: 'b-toaster-top-center',
            },
        );
    }
    // endregion
}
