


























import { Component, Model, Vue } from 'vue-property-decorator';
import I18n from '../I18n';
import { Org } from '../types';
import { SelectionFieldRow } from '../components';
import SingleOrgSelection from './SingleOrgSelection.vue';


const modelChangeEvent = 'change';
const i18n = new I18n('modules.budget.staffing_table.components.SingleOrgField');


@Component({
    name: 'SingleOrgField',
    components: {
        'field-row': SelectionFieldRow,
        'org-selection': SingleOrgSelection,
    },
})
export default class SingleOrgField extends Vue {
    // region Model, properties
    @Model(
        modelChangeEvent,
        {
            type: Object,
            required: false,
            default: null,
        },
    )
    public value!: Org | null;
    // endregion


    // region Lifecycle
    // noinspection JSUnusedLocalSymbols
    private created() {
        // region Model, properties
        this.$watch('value', (value: Org | null) => {
            if (this.localValue?.code !== value?.code) {
                this.localValue = value;
            }
        });
        // endregion


        // region Org
        this.$watch('localValue', (localValue: Org | null) => {
            if (this.value?.code !== localValue?.code) {
                this.$emit(modelChangeEvent, localValue);
            }
        });
        // endregion
    }

    // noinspection JSUnusedLocalSymbols
    private mounted() {
        if (this.value?.code !== this.localValue?.code) {
            this.localValue = this.value;
        }
    }
    // endregion


    // region Util
    private i18n = i18n;
    // endregion


    // region Org
    private localValue: Org | null = null;

    private getOrgText(): string | null {
        const org = this.localValue;
        if (org === null) return null;

        const type = org.type;
        let result = '';
        if (org.type === 'GU') {
            const gu = org.gu;
            result = gu.code;
            if (this.i18n.isKazakh) {
                result += ' - ' + gu.nameKk || '';
            } else {
                result += ' - ' + gu.nameRu;
            }
        } else if (org.type === 'KGKP') {
            const kgkp = org.kgkp;
            result = kgkp.bin;
            if (this.i18n.isKazakh) {
                result += ' - ' + kgkp.nameKk;
            } else {
                result += ' - ' + kgkp.nameRu;
            }
        } else {
            throw new Error(`Cannot process organization with unknown type \"${type}\"`);
        }

        return result;
    }
    // endregion


    // region Modal
    private modalOrg: Org | null = null;

    private modalVisible = false;

    private get modalOkDisabled(): boolean { return this.modalOrg === null; }

    private onModalOk() { this.localValue = this.modalOrg; }
    // endregion


    // region Field
    private onFieldClick() {
        this.modalOrg = this.localValue;
        this.modalVisible = true;
    }
    // endregion
}
