
























import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import { Comp } from '../types';
import PaginationPages from './PaginationPages.vue';


interface IData {
    page: number;
    maxPage: number;
    itemsPerPage: number;
}


const modelChangeEvent = 'change';
const itemsPerPageChangeEvent = 'items-per-page-change';
const defaultItemsPerPage = 25;

const isDataDifferent = (data1: IData, data2: IData): boolean => {
    return (
        (data1.page !== data2.page)
        ||
        (data1.itemsPerPage !== data2.itemsPerPage)
        ||
        (data1.maxPage !== data2.maxPage)
    );
};

const createData = (totalItems: number, itemsPerPage: unknown, page: unknown): IData => {
    const actualItemsPerPage = ((typeof itemsPerPage === 'number') && (itemsPerPage > 0) ? itemsPerPage : defaultItemsPerPage);

    const maxPage = Math.ceil(Math.max(totalItems, 0) / actualItemsPerPage);

    let actualPage: number;
    if ((typeof page === 'number') && (page >= 0) && (page <= maxPage)) {
        actualPage = page;
    } else {
        actualPage = 0;
    }

    return {
        page: actualPage,
        itemsPerPage: actualItemsPerPage,
        maxPage,
    };
};


@Component({
    components: {
        PaginationPages,
    },
})
export default class Pagination extends Vue {
    // region Model, properties
    @Model(
        modelChangeEvent,
        {
            type: Number,
            required: false,
            default: null,
        },
    )
    public value!: number | null;

    @Prop({
        type: Number,
        required: false,
        default: null,
    })
    public itemsPerPage!: number | null;

    @Prop({
        type: Number,
        required: true,
    })
    public totalItems!: number;
    // endregion


    // region Lifecycle
    // noinspection JSUnusedLocalSymbols
    private created() {
        // region Model, properties
        this.$watch('value', (value: number | null) => {
            if (value !== null) {
                if ((value >= 0) && (value <= this.data.maxPage) && (value !== this.data.page)) {
                    const newData = createData(this.totalItems, this.data.itemsPerPage, value);
                    this.setDataIfDifferent(newData);
                }
            }
        });

        this.$watch('itemsPerPage', (itemsPerPage: number | null) => {
            if ((itemsPerPage !== null) && (itemsPerPage > 0) && (itemsPerPage !== this.data.itemsPerPage)) {
                const newData = createData(this.totalItems, itemsPerPage, 0);
                this.setDataIfDifferent(newData);
            }
        });

        this.$watch('totalItems', (totalItems: number) => {
            const newData = createData(totalItems, this.data.itemsPerPage, this.data.page);
            this.setDataIfDifferent(newData);
        });
        // endregion


        // region Data
        this.$watch('data', (data: IData) => {
            if (this.itemsPerPage !== data.itemsPerPage) {
                this.$emit(itemsPerPageChangeEvent, data.itemsPerPage);
            }
            if (this.value !== data.page) {
                this.$emit(modelChangeEvent, data.page);
            }
        });
        // endregion
    }

    // noinspection JSUnusedLocalSymbols
    private mounted() {
        const newData = createData(this.totalItems, this.itemsPerPage, this.value);
        if (isDataDifferent(this.data, newData)) {
            this.data = newData;
        }
    }
    // endregion


    // region Data
    private itemsPerPageOptions: Array<Comp.DropdownItemDef<number>> = [
        { text: '15', value: 15 },
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 },
    ];

    private data: IData = {
        page: 0,
        itemsPerPage: 25,
        maxPage: 0,
    };

    private setDataIfDifferent(newData: IData) {
        if (isDataDifferent(this.data, newData)) {
            this.data = newData;
        }
    }

    private itemsPerPageChanged(value: number) {
        if (value !== this.data.itemsPerPage) {
            const newData = createData(this.totalItems, value, 0);
            this.setDataIfDifferent(newData);
        }
    }

    private pageChanged(value: number) {
        if (value !== this.data.page) {
            const newData = createData(this.totalItems, this.data.itemsPerPage, value);
            this.setDataIfDifferent(newData);
        }
    }
    // endregion
}
