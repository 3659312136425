
























import { Component, Prop, Vue } from 'vue-property-decorator';


@Component
export default class SelectionFieldRow extends Vue {
    // region Model properties
    @Prop({
        type: String,
        required: false,
        default: null,
    })
    public value!: string | null;

    @Prop({
        type: String,
        required: true,
    })
    public placeholder!: string;
    // endregion


    // region Methods
    private onClick(ev: MouseEvent) {
        this.$emit('click', ev);
    }
    // endregion
}
