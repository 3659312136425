
































































import { Component, Model, Prop, Vue } from 'vue-property-decorator';


const modelChangeEvent = 'change';

const positiveIntegerValidator = (alias: string, minValue: number, value: any): boolean => {
    if (typeof value !== 'number') {
        console.error(`${alias} is not number`, value);
        return false;
    }

    if (!value.isSafeInteger) {
        console.error(`${alias} is not "safe" integer`, value);
        return false;
    }

    if (value < minValue) {
        console.error(`${alias} is less than minimal value ${minValue}`, value);
        return false;
    }

    return true;
};

const sidePageCount = 2;


@Component
export default class PaginationPages extends Vue {
    @Model(modelChangeEvent, {
        type: Number,
        required: true,
        validator(value: any): boolean {
            return positiveIntegerValidator('value', 0, value);
        }
    })
    public readonly value!: number;

    @Prop({
        type: Number,
        required: true,
        validator(value: any): boolean {
            return positiveIntegerValidator('itemsPerPage', 1, value);
        }
    })
    public readonly itemsPerPage!: number;

    @Prop({
        type: Number,
        required: true,
        validator(value: any): boolean {
            return positiveIntegerValidator('totalItems', 0, value);
        }
    })
    public readonly totalItems!: number;


    private page = 0;

    private get totalPages(): number {
        if (this.itemsPerPage <= 0) {
            return 0;
        }
        if (this.totalItems <= 0) {
            return 0;
        }

        return Math.ceil(this.totalItems / this.itemsPerPage);
    }

    private get maxPage(): number {
        return Math.max(0, this.totalPages - 1);
    }

    private get hasPrevPage(): boolean {
        return (this.page > 0);
    }

    private get hasNextPage(): boolean {
        return (this.page < this.maxPage);
    }

    private get prevPages(): number[] {
        const result: number[] = [];

        let page = this.page - 1;
        let left = sidePageCount;
        while ((left > 0) && (page >= 0)) {
            result.unshift(page);
            page--;
            left--;
        }

        return result;
    }

    private get nextPages(): number[] {
        const result: number[] = [];

        let page = this.page + 1;
        let left = sidePageCount;
        while ((left > 0) && (page <= this.maxPage)) {
            result.push(page);
            page++;
            left--;
        }

        return result;
    }

    private get showFirstPage(): boolean {
        return (this.page > sidePageCount);
    }

    private get showFirstPageSpacer(): boolean {
        return (this.page > sidePageCount + 1);
    }

    private get showLastPage(): boolean {
        return (this.maxPage > (this.page + sidePageCount));
    }

    private get showLastPageSpacer(): boolean {
        return (this.maxPage > (this.page + sidePageCount + 1));
    }

    private pageString = '';

    private get parsedPage(): number | null {
        const trimmed = this.pageString.trim();

        if (trimmed.length === 0) {
            return null;
        }

        const value = parseFloat(trimmed);
        if (
            value.isSafeInteger
            && (String(value) === trimmed)
            && (value > 0)
            && (value <= this.totalPages)
        ) {
            return value - 1;
        }
        return null;
    }

    private get pageStringInvalid(): boolean {
        if (this.pageString.trim().length === 0) {
            return false;
        }

        return (this.parsedPage === null);
    }


    // noinspection JSUnusedLocalSymbols
    private created() {
        this.page = this.value;

        this.$watch('value', () => {
            this.page = this.value;
        });

        this.$watch('itemsPerPage', () => {
            this.pageString = '';
        });

        this.$watch('page', () => {
            if (this.value !== this.page) {
                this.$emit(modelChangeEvent, this.page);
            }
        });

        this.$watch('maxPage', () => {
            if (this.page > this.maxPage) {
                this.page = this.maxPage;
            }
        });
    }


    private onPrevPageClicked() {
        if (this.page > 0) {
            this.page--;
        }
    }

    private onPageClicked(page: number) {
        this.page = page;
    }

    private onPageStringConfirmed() {
        if (this.parsedPage !== null) {
            this.page = this.parsedPage;
        }
    }

    private onNextPageClicked() {
        if (this.page < this.maxPage) {
            this.page++;
        }
    }
}
