

























































































import { Values } from 'vue-i18n';
import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import NumberInput from './NumberInput.vue';
import { Comp, Dict } from '../types';


const modelChangeEvent = 'change';
const events = {
    model: modelChangeEvent,
    change: modelChangeEvent,
    changeBool: 'change-bool',
    changeDate: 'change-date',
    changeNormative: 'change-normative',
    changeNumber: 'change-number',
    changeSalaryRate: 'change-salary-rate',
    changeText: 'change-text',
    changeMilitaryRank: 'change-military-rank',
    changeMilitaryTitle: 'change-military-title'
};

const keySet = new Set<number>();
const getKey = (): number => {
    const rnd = (): number => {
        return Math.round(Math.random() * Number.MAX_SAFE_INTEGER);
    };

    let result = rnd();
    while (keySet.has(result)) {
        result = rnd();
    }
    keySet.add(result);
    return result;
};
const removeKey = (key: number) => {
    keySet.delete(key);
};


@Component({
    components: {
        'number-input': NumberInput
    }
})
export default class AddAttrField extends Vue {
    // region Модель, свойства
    @Model(modelChangeEvent, {
        required: false,
        default: null
    })
    public readonly value!: null | boolean | string | number | Date | Dict.MilitaryRank | Dict.MilitaryTitle;

    @Prop({
        type: Array,
        required: false,
        default: null
    })
    public readonly options!: null | Array<Comp.DropdownItemDef<null | boolean | string | number | Date>>;

    @Prop({
        type: String,
        required: true
    })
    public readonly type!: Dict.StEad.AddAttrType;

    @Prop({
        type: Array,
        required: false,
        default: () => []
    })
    public readonly militaryRanks!: Dict.MilitaryRank[];

    @Prop({
        type: Array,
        required: false,
        default: () => []
    })
    public readonly militaryTitles!: Dict.MilitaryTitle[];

    @Prop({
        type: String,
        required: false,
        default: null
    })
    public readonly labelI18n!: string | null;

    @Prop({
        type: String,
        required: false,
        default: null
    })
    public readonly label!: string | null;

    public get isList(): boolean {
        return (this.options !== null);
    }

    public get isBool(): boolean {
        return (this.type === 'BOOL');
    }

    public get isDate(): boolean {
        return (this.type === 'DATE');
    }

    public get isNumber(): boolean {
        return (this.type === 'NORMATIVE') || (this.type === 'NUMBER') || (this.type === 'SALARY_RATE');
    }

    public get isText(): boolean {
        return (this.type === 'TEXT');
    }

    public get isMilitaryRank(): boolean {
        return (this.type === 'MILITARY_RANK');
    }

    public get isMilitaryTitle(): boolean {
        return (this.type === 'MILITARY_TITLE');
    }

    public get componentLabel(): string {
        if (this.labelI18n !== null) {
            return String(this.$t(this.labelI18n));
        }
        if (this.label !== null) {
            return this.label;
        }
        return this.getTranslate('value');
    }

    public get militaryRankOptions(): Array<Comp.DropdownItemDef<Dict.MilitaryRank>> {
        const isKazakh = (this.$i18n.locale.trim().toLowerCase() === 'kk');
        const create = (value: Dict.MilitaryRank): Comp.DropdownItemDef<Dict.MilitaryRank> => {
            return {
                text: (isKazakh ? value.nameKk : value.nameRu),
                value: value
            };
        };

        const result: Array<Comp.DropdownItemDef<Dict.MilitaryRank>> = [];
        this.militaryRanks.forEach(value => {
            result.push(create(value));
        });
        return result;
    }

    public get militaryTitleOptions(): Array<Comp.DropdownItemDef<Dict.MilitaryTitle>> {
        const isKazakh = (this.$i18n.locale.trim().toLowerCase() === 'kk');
        const create = (value: Dict.MilitaryTitle): Comp.DropdownItemDef<Dict.MilitaryTitle> => {
            return {
                text: (isKazakh ? value.nameKk : value.nameRu),
                value: value
            };
        };

        const result: Array<Comp.DropdownItemDef<Dict.MilitaryTitle>> = [];
        this.militaryRanks.forEach(value => {
            result.push(create(value));
        });
        return result;
    }
    // endregion


    private key = getKey();


    // region Lifecycle
    // noinspection JSUnusedLocalSymbols
    private created() {
        this.localValue = this.value;

        this.$watch('value', () => {
            if (this.localValue !== this.value) {
                this.localValue = this.value;
            }
        });

        this.$watch('type', () => {
            this.localValue = null;
        });

        this.$watch('localValue', () => {
            if (this.value !== this.localValue) {
                this.$emit(modelChangeEvent, this.localValue);
            }

            let eventName: string | null = null;
            switch (this.type) {
                case 'BOOL':
                    eventName = events.changeBool;
                    break;
                case 'DATE':
                    eventName = events.changeDate;
                    break;
                case 'NORMATIVE':
                    eventName = events.changeNormative;
                    break;
                case 'NUMBER':
                    eventName = events.changeNumber;
                    break;
                case 'SALARY_RATE':
                    eventName = events.changeSalaryRate;
                    break;
                case 'TEXT':
                    eventName = events.changeText;
                    break;
                case 'MILITARY_RANK':
                    eventName = events.changeMilitaryRank;
                    break;
                case 'MILITARY_TITLE':
                    eventName = events.changeMilitaryTitle;
                    break;
                default:
                    break;
            }
            if (eventName !== null) {
                this.$emit(eventName, this.localValue);
            }
        });
    }

    // noinspection JSUnusedLocalSymbols
    private destroyed() {
        removeKey(this.key);
    }
    // endregion


    // region Утилиты
    private getTranslate(key: string, values?: Values): string {
        return String(this.$t(`modules.budget.staffing_table.*AddAttrField*.${key}`, values));
    }
    // endregion


    // region Данные
    private localValue: null | boolean | string | number | Date | Dict.MilitaryRank | Dict.MilitaryTitle = null;

    private get valueAsDate(): Date | null {
        if (this.localValue instanceof Date) {
            return this.localValue;
        }
        return null;
    }

    private setListValue(value: null | boolean | string | number | Date) {
        if (this.isList) {
            this.localValue = value;
        }
    }

    private toggleBoolValue() {
        if (this.isBool) {
            this.localValue = !this.localValue;
        }
    }

    private setDateValue(value?: Date | null) {
        if (this.isDate) {
            if (value instanceof Date) {
                this.localValue = value;
            } else {
                this.localValue = null;
            }
        }
    }

    private setNumberValue(value?: number | null) {
        if (this.isNumber) {
            if (typeof value === 'number') {
                this.localValue = value;
            } else {
                this.localValue = null;
            }
        }
    }

    private setTextValue(value?: string | null) {
        if (this.isText) {
            if (typeof value === 'string') {
                this.localValue = value;
            } else {
                this.localValue = null;
            }
        }
    }

    private setMilitaryRank(value: Dict.MilitaryRank) {
        if (this.isMilitaryRank) {
            // noinspection SuspiciousTypeOfGuard
            if (value instanceof Object) {
                this.localValue = value;
            } else {
                this.localValue = null;
            }
        }
    }

    private setMilitaryTitle(value: Dict.MilitaryTitle) {
        if (this.isMilitaryRank) {
            // noinspection SuspiciousTypeOfGuard
            if (value instanceof Object) {
                this.localValue = value;
            } else {
                this.localValue = null;
            }
        }
    }
    // endregion
}
